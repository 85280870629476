import { IconButton } from "@mui/material";
import { apiCall } from "../../services/authServieces";
import ShowSnackBar from "../../components/snackBar";
import React, { useState, useEffect } from "react";
import "../../css/wb_manage_dealer.css";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import { formatDate, getRandomNum } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import TablePagination from "@mui/material/TablePagination";  // Ensure this import is present

const AddCallback = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);  // Modal state

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);

  const [formValues, setFormValues] = useState({
    callbackurl: "",
    status: "0",  // Default status
  });

  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const columns = [
    { id: "company_name", label: "Company" },
    { id: "user_id", label: "Customer Id" },
    { id: "user_name", label: "Customer Name" },
    { id: "user_phone", label: "Mobile" },
    { id: "user_email", label: "Email Id" },
    { id: "created_date", label: "Created Date" },
    { id: "action", label: "Action" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Fetch users based on search and pagination
  const fetchUsers = async (page = 1, limit = 10) => {
    setLoading(true);
    try {
      const res = await apiCall({
        endpoint: `admin/get-users-forcallback?page=${page}&limit=${limit}`,
        method: "GET",
      });
  
      if (res?.success) {
        setRows(res?.data || []);
        setCount(res?.total);
      } else {
        setSnackBar({
          open: true,
          severity: "error",
          message: res?.message || "An error occurred",
        });
      }
    } catch (err) {
      console.error(err);
      setSnackBar({
        open: true,
        severity: "error",
        message: err?.response?.data?.message || "An error occurred",
      });
    } finally {
      setLoading(false);
    }
  };
  
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);  // Set the new page
    await fetchUsers(newPage + 1, rowsPerPage);  // Fetch new data for the updated page
  };

  const handleChangeRowsPerPage = async (event) => {
    const newRowsPerPage = +event.target.value;  // Get the new rows per page value
    setRowsPerPage(newRowsPerPage);  // Update rows per page state
    setPage(0);  // Reset to first page
    await fetchUsers(1, newRowsPerPage);  // Fetch data with the new rows per page and reset to first page
  };

  const handleClick = (event, row) => {
    setSelectedRow(row);  // Track selected row
    setFormValues({
      callbackurl: row.send_url || "",  // Set the callback URL
      status: row.callback_status === "1" ? "Activate" : "Deactivate",  // Set status based on callback_status
    });
    setIsAddModalOpen(true);  // Open modal
  };

  const modalClose = () => {
    setIsAddModalOpen(false);  // Close the modal
  };

  useEffect(() => {
    fetchUsers(page + 1, rowsPerPage);  // Only fetch data on first render
  }, []);


  const saveCallback = async () => {
    setLoading(true);
    if (formValues.status === "Activate" && !formValues.callbackurl) {
      setSnackBar({
        open: true,
        severity: "error",
        message: "Please provide a callback URL to activate the status.",
      });
      setLoading(false);
      return; // Prevent API call if callback URL is not provided
    }
    
    
    try {
      const data = {
        user_id: selectedRow.user_id,  // Assuming `selectedRow` contains user data
        business_no: selectedRow.business_no || "",  // Assuming `business_no` exists in `selectedRow`
        callbackstatus: formValues.status === "Activate" ? 1 : 0,  // Map status to 1 or 0
        callbackurl: formValues.callbackurl,
      };
  
      const res = await apiCall({
        endpoint: 'admin/save-user-callbackdata', // Modify this endpoint based on your API
        method: 'POST',
        payload:data,
      });
  
      if (res?.success) {
        setSnackBar({
          open: true,
          severity: "success",
          message: "Callback saved successfully!",
        });
        modalClose(); // Close modal after success
        fetchUsers(); // Refresh the users list
      } else {
        setSnackBar({
          open: true,
          severity: "error",
          message: res?.message || "Error saving callback.",
        });
      }
    } catch (err) {
      setSnackBar({
        open: true,
        severity: "error",
        message: err?.response?.data?.message || "An error occurred.",
      });
    } finally {
      setLoading(false); // Reset loading state
    }
  };
  useEffect(() => {
    // Auto-close the Snackbar after it opens
    if (snackBar.open) {
      const timer = setTimeout(() => {
        setSnackBar({ ...snackBar, open: false });
      }, 2000); // Set duration to 3000ms (3 seconds)
  
      return () => clearTimeout(timer); // Clean up timer if component unmounts
    }
  }, [snackBar.open]); // Trigger effect when `snackBar.open` changes
  

  return (
    <>
      <div className="Template_id_contian">
        <h4 className="Head_title AdminSearchContain">Add Callback</h4>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table" stickyHeader  className="Table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow hover key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id}>
                          {column.id === "created_date" ? (
                            formatDate(value)
                          ) : column.id === "action" ? (
                            <IconButton
                              onClick={(event) => handleClick(event, row)} // Open modal on click
                            >
                           <EditIcon className="icon-spacing" />
                            </IconButton>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          </TableContainer>
        </Paper>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      {/* Modal */}
      {isAddModalOpen && (
        <div className="modal fade show d-block manage_dealer_form wb_mange_content add_callback">
          <div className="modal-dialog-lg add_callback_modal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Callback</h5>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label>Callback URL</label>
                    <input
                      type="text"
                      name="callbackurl"
                      value={formValues.callbackurl}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="form-group">
  <label>Status</label>
  <select
    name="status"
    value={formValues.status}
    onChange={handleInputChange}
  >
    <option value="Activate">Activate</option>
    <option value="Deactivate">Deactivate</option>
  </select>
</div>

                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={modalClose}  // Close the modal
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary" onClick={saveCallback}>
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Snackbar */}
      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        handleClose={() => setSnackBar({ ...snackBar, open: false })}
        autoHideDuration={3000} 
      />
    </>
  );
};

export default AddCallback;
