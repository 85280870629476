import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './sidebar.css';
import List from '../../Assets/images/list.png';
import CloseIcon from '../../Assets/images/close_icon.png';
import Dash from '../../Assets/images/dashboard.png';
import ComposeSms from '../../Assets/images/compose_sms.png';
import Report from '../../Assets/images/report.png';
import transactionIcon from '../../Assets/images/transaction.png'
import CreateIcon from '../../Assets/images/edit.png';
import { useUIContext } from '../../context/index';
import Manage from '../../Assets/images/manage.png';
import Logo from "../../Assets/images/logo.png";
import alienics from "../../Assets/logo/alienics_logo.png";

const UserSidebar = () => {
  const location = useLocation();
  const { isSidebarCollapsed, setIsSidebarCollapsed } = useUIContext();

  const[admintype,setadmintype]=useState('manthanadmin');


  const userData = JSON.parse(localStorage.getItem('user-cred'));
  const adminid = userData?.user?.admin_id;

  useEffect(() => {
    if (adminid) {
      setadmintype(adminid);
    }
  }, [adminid]); 




  const isActivePath = (path) => location.pathname === path;

  useEffect(() => {
  }, [location]);

  return (
    <div className="Sidebar" id="Sidebar">
      <div className="Sidebar_head">
        {/* <NavLink to="#"><img src={SmsIcon} alt="img" /></NavLink> */}
        {admintype === 'manthanadmin' ? (
          <>
            <NavLink to="#"><img src={Logo} alt="Logo" /></NavLink>
            <NavLink to="#">EASYGOWP</NavLink>
          </>
        ) : (
          <NavLink to="#" className="other_logo"><img src={alienics} alt="Alienics Logo" /></NavLink>
        )}
    

    
        <button id="Menu_close" onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}><img src={CloseIcon} alt="img" /></button>
      </div>
      <div className="Sidebar_contain">
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <NavLink to="/">
                <button className={`accordion-button bg_none collapsed ${isActivePath('/') ? 'active' : ''}`} type="button">
                  <img src={Dash} alt="img" /> <span>Dashboard</span>
                </button>
              </NavLink>
            </h2>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className={`accordion-button collapsed ${isActivePath('/wbquickcampaigns') || isActivePath('/wbcustomizecampaigns') ? 'active' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#Dropdown_1" aria-expanded="false" aria-controls="Dropdown_1">
                <img src={ComposeSms} alt="img" /> <span>Campaigns</span>
              </button>
            </h2>
            <div id="Dropdown_1" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <NavLink to='/wbquickcampaigns' className={({ isActive }) => isActive ? 'active' : ''}>
                  <img src={List} alt="img" />Quick Campaigns
                </NavLink>
                <NavLink to="/wbcustomizecampaigns" className={({ isActive }) => isActive ? 'active' : ''}>
                  <img src={List} alt="img" />Customize Campaigns
                </NavLink>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className={`accordion-button collapsed ${isActivePath('/wbtemplate') || isActivePath('/wbmanagemedia') || isActivePath('/wbmanagegroups') || isActivePath('/wbmanageblocknumber') || isActivePath('/admin/manage-dealer') || isActivePath('/wbdeveloperstools') ? 'active' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#Dropdown_3" aria-expanded="false" aria-controls="Dropdown_3">
                <img src={Manage} alt="img" /> <span>Manage</span>
              </button>
            </h2>
            <div id="Dropdown_3" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <NavLink to="/wbtemplate" className={({ isActive }) => isActive ? 'active' : ''}>
                  <img src={List} alt="img" />Template
                </NavLink>
                <NavLink to="/wbmanagemedia" className={({ isActive }) => isActive ? 'active' : ''}>
                  <img src={List} alt="img" />Media
                </NavLink>
                <NavLink to="/wbmanagegroups" className={({ isActive }) => isActive ? 'active' : ''}>
                  <img src={List} alt="img" />Group
                </NavLink>
                <NavLink to="/wbmanageblocknumber" className={({ isActive }) => isActive ? 'active' : ''}>
                  <img src={List} alt="img" />Block Number
                </NavLink>
                <NavLink to="/wbdeveloperstools" className={({isActive}) => isActive ? 'active' : ''}>
                  <img  src={List} alt='img' />Developer Tool
                </NavLink>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className={`accordion-button collapsed ${isActivePath('/createbot') ? 'active' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#Dropdown_create" aria-expanded="false" aria-controls="Dropdown_create">
                <img src={CreateIcon} alt="img" /> <span>Bot</span>
              </button>
            </h2>
            <div id="Dropdown_create" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <NavLink to="/createbot" className={({ isActive }) => isActive ? 'active' : ''}>
                  <img src={List} alt="img" />Manage Bot
                </NavLink>

              </div>
            </div>
          </div>

          
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className={`accordion-button collapsed ${isActivePath('/wbdeliveryreport') || isActivePath('/wbsessionrecievedreport') || isActivePath('/wbmisreport') || isActivePath('/wbdealerwisemisreport') || isActivePath('/wblogindetails') || isActivePath('/campaignwise_report') ? 'active' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#Dropdown_4" aria-expanded="false" aria-controls="Dropdown_4">
                <img src={Report} alt="img" /> <span>Whatsapp Report</span>
              </button>
            </h2>
            <div id="Dropdown_4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <NavLink to="/wbdeliveryreport" className={({ isActive }) => isActive ? 'active' : ''}>
                  <img src={List} alt="img" />Delivery Report
                </NavLink>
                <NavLink to="/wbsessionrecievedreport" className={({ isActive }) => isActive ? 'active' : ''}>
                  <img src={List} alt="img" />Session Received
                </NavLink>
                <NavLink to="/wbmisreport" className={({ isActive }) => isActive ? 'active' : ''}>
                  <img src={List} alt="img" />MIS Report
                </NavLink>
                {/* <NavLink to="/wbdealerwisemisreport" className={({ isActive }) => isActive ? 'active' : ''}>
                  <img src={List} alt="img" />DealerWise MIS
                </NavLink> */}
                {/* <NavLink to="/wblogindetails" className={({ isActive }) => isActive ? 'active' : ''}>
                  <img src={List} alt="img" />Login Details
                </NavLink> */}
                <NavLink to="/campaignwise_button_report" className={({ isActive }) => isActive ? 'active' : ''}>
                  <img src={List} alt="img" />Button Report
                </NavLink>
                <NavLink to="/campaignwise_report" className={({ isActive }) => isActive ? 'active' : ''}>
                  <img src={List} alt="img" />Campaign Report
                </NavLink>
             
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className={`accordion-button collapsed ${isActivePath('/userwalletdetails') || isActivePath('/campaignwise_transactions')  ? 'active' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#Dropdown_5" aria-expanded="false" aria-controls="Dropdown_5">
                <img src={transactionIcon} alt="img" /> <span>Transaction</span>
              </button>
            </h2>
            <div id="Dropdown_5" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                <NavLink to="/userwalletdetails" className={({ isActive }) => isActive ? 'active' : ''}>
                  <img src={List} alt="img" />Wallet Details 
                </NavLink>
                <NavLink to="/campaignwise_transactions" className={({ isActive }) => isActive ? 'active' : ''}>
                  <img src={List} alt="img" />CampaignWise Details
                </NavLink>
              </div>
            </div>
          </div>

          

          {/* Add more user-specific sidebar items */}
        </div>
      </div>
      <div className="sidebar_bg_img"></div>
    </div>
  );
};

export default UserSidebar;
