import React, { useRef, useState, useEffect } from 'react';
import '../css/wb_quick_campaigns.css';
import Clear from '../Assets/images/clear.png';
import Unique from '../Assets/images/unique.png';
import Import from '../Assets/images/import.png';
import { Link } from 'react-router-dom';
import Papa from 'papaparse';
import ShowSnackBar from "../components/snackBar";
// import axios from 'axios';
import { apiCall } from '../services/authServieces';
import { ClipLoader } from 'react-spinners';
import { PhoneInput } from "react-international-phone";


const Wb_quick_campaigns = () => {
  const [loader, setLoader] = useState(false);
  const [snackBar, setSnackBar] = useState({ open: false, severity: true, message: "" });
  const [countrycode, setcountrycode] = useState('')
  const [isInitialState, setIsInitialState] = useState('');
  const [contactCount, setContactCount] = useState(0);
  const [fileType, setFileType] = useState('');
  const [urlType, setUrlType] = useState('');
  const [Campaign, setCampaign] = useState('');
  const [contact, setcontact] = useState('');
  const [template, settemplate] = useState('');
  const [message, setmessage] = useState('');
  const [maxLimit, setMaxLimit] = useState(0)
  const [templatetype, settemplatetype] = useState('');
  const [contactdata, setcontactdata] = useState([]);
  const [templatedata, setemplatedata] = useState([])
  const [filed, setfiledata] = useState();
  const fileInputRef = useRef(null);
  const [modalData, setModalData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [totalContacts, setTotalContacts] = useState(0);
  const [loading, setLoading] = useState(false); // Loader state
  const [groupData, setGroupData] = useState([]); //  new state for group data

  const [blockNum, setblockNum] = useState([]);

  const [templateID, settemplateID] = useState('');
  const [templatename, settemplatename] = useState('');
  const [templatecat, settemplatecat] = useState('');
  const [templatelang, settemplatelang] = useState('');

  const [pltPrice, setpltPrice] = useState('');
  const [msgPrice, setmsgPrice] = useState('');
  const [userBalance, setuserBalance] = useState('');
  const [finalvalue, setfinalvalue] = useState('');
  const [phoneNumber, setPhoneNumber] = useState("");

  const checkfiledata = useRef(null);
  const checkurldata = useRef(null);

  const handleClear = () => {
    setIsInitialState('');
    setContactCount(0);
  };

  useEffect(() => {
    // Call the function to fetch contact data
    Contactdata();
    Groupnamedata();
    Handleblocknodata();
  }, []);


  const Handleblocknodata = async () => {
    setLoader(true)
    try {
      let response = await apiCall({ endpoint: 'api/get_block_number_data', method: 'get' });

      if (response.success === true) {

        let blocknumdata = response.data.map(item => item.block_number)

        setblockNum(blocknumdata)

      } else {
        setblockNum([])

      }



    } catch (err) {
      console.log('error in fechting blocknodata', err)
    } finally {
      setLoader(false);
    }

  }

  const handleInputChange = (e) => {

    const enteredVal = e.target.value;
    const onlyNum = enteredVal.replace(/[^0-9\n]/g, '');
    setIsInitialState(onlyNum);

    const newLineCount = (enteredVal.match(/\n/g) || []).length;
    setContactCount(newLineCount + 1);
  };

  const eliminateDuplicates = (arr) => {
    const obj = {};
    arr.forEach(item => {

      obj[item] = true;

    });

    return Object.keys(obj);

  };

  const handleUnique = () => {

    // Split input by newlines, eliminate duplicates, and join back
    const uniqueContacts = eliminateDuplicates(isInitialState.split('\n')).join('\n');
    setIsInitialState(uniqueContacts);

    // Update the contact count after eliminating duplicates
    const newLineCount = (uniqueContacts.match(/\n/g) || []).length;
    // setContactCount(newLineCount + 1);
    setContactCount(uniqueContacts.trim() === '' ? 0 : newLineCount + 1)
  };

  // Get Businesss number //
  const Contactdata = async () => {
    setLoader(true);
    try {
      let response = await apiCall({ endpoint: 'api/get-business-no', method: 'get' });

      if (response.data && response.data.length > 0) {
        // Extract business_no from each object in the array
        const businessNumbers = response.data.map(item => item.business_no);

        // Set the extracted business numbers to contactdata
        setcontactdata(businessNumbers);
      } else {
        setcontactdata([]); // Clear the contact data if no data is returned
      }
    } catch (error) {
      console.error('Error fetching contact data:', error);
      setcontactdata([]); // Clear the contact data in case of an error
    } finally {
      setLoader(false);
    }
  };


  // Get Group name 


  const Groupnamedata = async () => {
    setLoader(true);
    try {
      let response = await apiCall({ endpoint: 'api/getGroupnameData', method: 'get' });


      // console.log(response,'response')
      if (response.data && response.data.length > 0) {
        // Assuming the response contains an array of group names
        const groups = response.data.map(item => ({
          name: item.groupname, // Update this key as per your backend response
          id: item.groupid     // Assuming you have a group_id
        }));

        setGroupData(groups); // Set the fetched group data in state
      } else {
        setGroupData([]); // Clear the group data if no data is returned
      }
    } catch (error) {
      console.error('Error fetching group data:', error);
      setGroupData([]); // Clear the group data in case of an error
    } finally {
      setLoader(false);
    }
  };





  function checkvalues(values) {

    var finalvalues = values.map((ele) => {

      if (isNaN(ele)) {
        ele = ''
      } else if (ele.length < 10 || ele.length > 10 || ele[0] <= 5) {

        ele = ''
      }

      return ele
    })

    var data = finalvalues.filter(element => element !== '');
    return data;

  }

  // This is Without Limit function

  // const handleFileChange = (event) => {
  //   const fileUpload = event.target.files[0];

  //   if (fileUpload) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const csvContent = e.target.result;

  //       Papa.parse(csvContent, {
  //         header: true,
  //         skipEmptyLines: true,
  //         complete: (results) => {
  //           const allNumbers = [];
  //           results.data.forEach(row => {
  //             for (let key in row) {
  //               const value = row[key];
  //               if (value && /^\d+$/.test(value)) {
  //                 allNumbers.push(value);
  //               }
  //             }
  //           });

  //           const filteredNumbers = allNumbers.filter(num => num.length === 10);
  //           const formattedNumbers = filteredNumbers.join('\n');
  //           setIsInitialState(formattedNumbers);

  //           const newLineCount = (formattedNumbers.match(/\n/g) || []).length;
  //           setContactCount(newLineCount + 1);
  //         }
  //       });
  //     };
  //     reader.readAsText(fileUpload);
  //   }
  // };

  // This is limit function while upload

  const handleFileChange = (event) => {
    const fileUpload = event.target.files[0];

    if (fileUpload) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvContent = e.target.result;

        Papa.parse(csvContent, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            let parsedData = results.data;

            // Limit the processing to 10,000 rows
            const MAX_ROWS = 50000;
            if (parsedData.length > MAX_ROWS) {
              parsedData = parsedData.slice(0, MAX_ROWS);
              alert(`Only the first ${MAX_ROWS} rows will be processed.`);
            }

            const allNumbers = [];
            parsedData.forEach(row => {
              for (let key in row) {
                const value = row[key];
                if (value && /^\d+$/.test(value)) { // Check if the value is numeric
                  allNumbers.push(value);
                }
              }
            });


            const filteredNumbers = allNumbers.filter(num => num.length === 10);

            // Join numbers into a string with newline separators
            const formattedNumbers = filteredNumbers.join('\n');

            // Set the formatted numbers to the state
            setIsInitialState(formattedNumbers);

            // Update the contact count
            const newLineCount = (formattedNumbers.match(/\n/g) || []).length;
            setContactCount(newLineCount + 1);
          }
        });
      };
      reader.readAsText(fileUpload);
    }
  };


  const handleImportClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };



  const handleFileTypeChange = async (e) => {
    const selectedFileType = e.target.value;

    setFileType(selectedFileType);
    settemplatetype(selectedFileType);

    setmessage('');
    setfiledata('');

    try {
      const payload = {
        "templatetype": selectedFileType,
        "requesttype": 'Quick'
      }
      const response = await apiCall({ endpoint: 'api/gettemplate_data', method: 'POST', payload: payload })

      if (response.data && response.data) {

        const templateOptions = response.data.map(item => ({
          name: item.temp_name,
          id: item.temp_id
        }));

        // Setting the template data
        setemplatedata(templateOptions);

      } else {
        setemplatedata([]); // Clear the template data if no data is returned
      }
    } catch (error) {
      console.error('Error fetching template data:', error);
      setemplatedata([]); // Clear the template data in case of an error
    }
  };


  const SentData = async (event) => {

    event.preventDefault();
    try{
   



    // Validation for required fields
    if (isInitialState.trim() === '') {
      return alert('Please Enter a Value in Number Field');
    }
    if (Campaign.trim() === '') {
      return alert('Please Enter Campaign Title');
    }
    if (contact.trim() === '') {
      return alert('Please Select Contact');
    }
    if (fileType !== 'Text' && urlType.trim() === '' && !filed) {
      return alert('Please Enter a Media URL or Upload a File');
    }
    if (template.trim() === '') {
      return alert('Please Select Template');
    }
    if (message.trim() === '') {
      return alert('Please Enter a Message');
    }
    if (countrycode.trim() === '') {
      return alert('Please Enter Valid Country Code');
    }
    let finalnumbers = '';
    let sendMsgprice = '';
    let Unfilternumbers = isInitialState.split('\n');
    if (countrycode === '+91') {

      finalnumbers = checkvalues(Unfilternumbers);

      finalnumbers = finalnumbers.filter(number => !blockNum.includes(number));

      sendMsgprice = (Number(pltPrice) + Number(msgPrice)) * finalnumbers.length;


    } else {


      finalnumbers = Unfilternumbers;

      finalnumbers = finalnumbers.filter(number => !blockNum.includes(number));

      let payloaddata = {
        "countrycode": countrycode
      }

      let international_pricedata = await apiCall({ endpoint: 'api/getinternational_price', method: 'post', payload: payloaddata })

      if (international_pricedata.success) {

        if (international_pricedata?.data.length) {
          const priceData = international_pricedata.data[0]
          if (templatecat?.toUpperCase() === 'UTILITY') {
            const totalNums = finalnumbers.length
            const finalPrice = (Number(pltPrice) + parseFloat(priceData.utility_price)) * totalNums
            sendMsgprice = finalPrice;

          } else {
            const totalNums = finalnumbers.length
            const finalPrice = (Number(pltPrice) + parseFloat(priceData.marketing_price)) * totalNums
            sendMsgprice = finalPrice;
          }
          // console.log(priceData,templatecat)
        }

      } else {

        return alert('You not able to send International Message Please contact Admin')

      }

    }

    // if (finalnumbers.length < 2) {

    //   return alert('Please Add At Least Two Numbers')

    // }




    // // Check if sendMsgprice is a valid number
    // if (isNaN(sendMsgprice)) {
    //     return alert('Send message price is not a valid number');
    // }


    let finalsendprice = parseFloat(sendMsgprice);

    let finaluserBal = parseFloat(userBalance);

    // if (isNaN(finalsendprice) || isNaN(finaluserBal)) {
    //     return alert('One of the values is not a valid number');
    // }

    if (finalsendprice > finaluserBal) {
      return alert('Your Balance is Low');
    } else {




      let datafinal = sendMsgprice;


      setfinalvalue(prev => prev = datafinal)



      const modalDataToShow = finalnumbers.slice(0, 5).map((number) => ({
        mobile: number,
        message: message,
        length: message.length,
      }));


      setTotalContacts(finalnumbers.length);

      setModalData(modalDataToShow);

      setShowModal(true);

  

    }

  }catch(error){


    if (error?.response) {
     
      setSnackBar({
          open: true,
          severity: false,
          message: error?.response?.data?.error?.sqlMessage || error?.response?.statusText || 'Something went wrong',
      });
  } else {
      // General error handling
      setSnackBar({
          open: true,
          severity: false,
          message: 'An unexpected error occurred. Please try again later.',
      });
  }
  }
  };

  const handleSendNow = async () => {
    if (loading) return;
    setLoading(true); // Start loader
    try {

      const formData = new FormData();

      let Unfilternumbers = isInitialState.split('\n')


      let finalnumbers=Unfilternumbers


      if(countrycode=='+91'){

      finalnumbers = checkvalues(Unfilternumbers)
      
    }


      formData.append('template_id', templateID);
      formData.append('template_name', templatename);
      formData.append('template_lang', templatelang);
      formData.append('template_cat', templatecat);
      formData.append('contacts', finalnumbers);
      formData.append('campaigntitle', Campaign);
      formData.append('businessNo', contact);
      formData.append('msgtype', fileType);
      formData.append('urltype', urlType);
      formData.append('countrycode', countrycode);


      formData.append('totalmsgprice', finalvalue)

      if (filed) {
        formData.append('file', filed);
      }
      formData.append('message', message);

      const response = await apiCall({
        endpoint: 'api/save_wb_quick_message',
        method: 'POST',
        payload: formData
      });

      if (response?.success === true) {
        alert("Data Send Successfull")

        // setSnackBar({
        //   open: true,
        //   severity: response?.success,
        //   message: response?.msg,
        // });


        window.location.reload();
        // Clear the form or show a success message here if needed
      }

    } catch (error) {

      // // if any error in sending data
      // alert('please try again')

      setSnackBar({
        open: true,
        severity: error?.response?.success,
        message: error?.response?.msg || "An error occurred",
      });

      setLoading(false);


    } finally {

      setLoading(false);
      // window.location.reload(); // Reload the page after success or error

    }
  };


  const handletemplatename = async (e) => {

    try {

      let selectedTempName = e.target.value;

      if (selectedTempName == '') {


        setmessage('')

        settemplatename('')
        settemplatecat('')
        settemplatelang('')
        setuserBalance('')

        settemplateID('')

        settemplate('')

        setpltPrice('');
        setmsgPrice('')

      } else {

        let payload = {
          "templatename": selectedTempName
        }
        // let responsemsg=await axios.post('http://localhost:8000/getresponse_msg', { templatename: selectedTempName })


        const responsemsg = await apiCall({ endpoint: 'getresponse_msg', method: 'POST', payload: payload })

        settemplatename(responsemsg?.data?.temp_name)
        settemplatecat(responsemsg?.data?.cat)
        settemplatelang(responsemsg?.data?.lang)

        setuserBalance(responsemsg?.totaldata?.balance)

        if (responsemsg.data.cat?.toUpperCase() === 'UTILITY') {

          setpltPrice(responsemsg?.balancedata?.plt_price);
          setmsgPrice(responsemsg?.balancedata?.utility_price)

        } else {
          setpltPrice(responsemsg?.balancedata?.plt_price);
          setmsgPrice(responsemsg.balancedata?.marketing_price)

        }

        settemplateID(responsemsg?.data?.temp_id)

        settemplate(selectedTempName)

        setmessage('')

        setmessage(responsemsg.data.message)
        setMaxLimit(responsemsg?.data?.message?.length);


      }



    } catch (err) {

      console.log(err)
    }
  }


  // const handleUserFilechange = async (e) => {
  //   try {

  //     let data = e.target.files[0];

  //     setfiledata(data);

  //     if (data) {
  //       checkurldata.current.disabled = true
  //     }

  //   } catch (err) {

  //     console.log(err)

  //   }


  // }

  const handleUserFilechange = async (e) => {
    try {
      let data = e.target.files[0];

      if (data) {
        // Check if the file size is less than 2 MB (2 * 1024 * 1024 bytes)
        if (data.size < 2 * 1024 * 1024) {

          setfiledata(data);
          checkurldata.current.disabled = true;
        } else {
          alert("File size should be less than 2 MB.");
          // Optionally, you can clear the file input
          e.target.value = null;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };



  const HandlegroupNum = async (e) => {
    const groupId = e.target.value;
    const isChecked = e.target.checked;
    // console.log(groupId,'groupid',isChecked,'ischecked')

    try {
      if (isChecked) {

        let payload1 = { groupId: groupId };

        const response = await apiCall({ endpoint: 'api/GetgroupNumbers', method: 'POST', payload: payload1 });

        const newNumbersArray = response.data;

        const newNumbers = newNumbersArray.map(item => item.mobile).join('\n');

        setIsInitialState((prevState) => {
          return prevState ? `${prevState}\n${newNumbers}` : newNumbers;
        });

        let lenthnewnum = newNumbers.split('\n').length
        if (lenthnewnum > 0) {

          setContactCount(prevcount => prevcount + lenthnewnum);
        }


      } else {
        // Optionally handle the unchecked case if needed
        console.log('Checkbox unchecked, handle accordingly');
      }

    } catch (error) {
      console.error('API error:', error);
    }
  };


  const handleurlchange = async (e) => {

    const urldata = e.target.value;

    try {

      if (urldata.length > 1) {

        setUrlType(urldata)
        checkfiledata.current.disabled = true

      } else {

        checkfiledata.current.disabled = false

      }

    }
    catch (err) {

      console.log(err)

    }

  }

  const handleCloseSnackBar = () => {
    setSnackBar((prevState) => ({ ...prevState, open: false }));
  };


  const handlePhoneInputChange = (newCountryCode) => {
    setcountrycode(newCountryCode);
    settemplate('');
    const extractedCountryCode = newCountryCode?.split(" ")[0] || "";
    setPhoneNumber(extractedCountryCode);
  };



  return (
    <>
      <div className="WB_Quick_contian">
        <h4 className="Head_title">Add Campaign</h4>
        <div className="WB_Quick_Card">
          <div className="row gy-3">
            <div className="col-lg-6">
              <label htmlFor="to_numbers" className="mb-2">To Numbers <span className='required_icon'>*</span></label>

              <textarea name="numbers" rows="4" id="to_numbers" placeholder="Enter a number... You can send SMS in batches up to 10,000 at a time" value={isInitialState} onChange={handleInputChange}></textarea>

              <div className="List_cardQuick">
                <span>Total Contacts : <span >{contactCount}</span> </span>
                <div className="Button_list gy-4z">
                  <button className="clear_bg" onClick={handleClear}><img src={Clear} alt=" img" className="icon" />Clear</button>
                  <button className="unique_bg" onClick={handleUnique}><img src={Unique} alt=" img" className="icon" />Unique</button>
                  <button className="impoer_bg" onClick={handleImportClick}><img src={Import} alt=" img" className="icon" />Import</button>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="Group_name">
                <div className="d-flex justify-content-between mb-2">
                  <span>Group Name </span>
                  <Link to="/wbmanagegroups" className="Plus_icon"><span>+</span> New Group</Link>
                </div>
                <div className="Group_d">
                  <ul className="Group_list">
                    {groupData.length > 0 ? (
                      groupData.map((group, index) => (
                        <li key={index}>
                          <input
                            type="checkbox"
                            value={group.id}
                            onChange={HandlegroupNum}


                          />
                          <p>{group.name}</p>
                        </li>
                      ))
                    ) : (
                      <li></li>
                    )}
                  </ul>
                </div>
              </div>
              
            </div>
          </div>
          <div className="row gy-3">
            <div className="col-lg-6">
              <div className="form_group1 form_groupInput">
                <label htmlFor="title">Campaign Title <span className='required_icon'>*</span></label>
                <input type="text" placeholder="Enter Campaign Title" value={Campaign} onChange={e => setCampaign(e.target.value)} id="title" />
              </div>
            </div>
            <div className='country_input_box col-lg-2'>
                <p>Country Code <span className='required_icon'>*</span></p>
                <div className='country_code_input form_group11'>
                  <PhoneInput
                    defaultCountry="in"
                    id="phoneNo"
                    value={countrycode}
                    onChange={handlePhoneInputChange}
                    className="countrycode"
                  />
                  <input
                    className='countryCodeInput'
                    id="countryCodeInput"
                    type="text"
                    value={phoneNumber}
                    readOnly
                  />
                </div>
              </div>
            <div className="col-lg-4">
              <div className="form_group1">
                <label htmlFor="contact">Contact <span className='required_icon'>*</span> </label>
                <select id="contact" value={contact} onChange={e => setcontact(e.target.value)}>
                  <option value="">None</option>


                  {contactdata.map((row, index) => (
                    <option key={index} value={row}>{row}</option>
                  ))}

                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form_group1">
                <label htmlFor="templatetype">Template Type <span className='required_icon'>*</span></label>
                <select id="templatetype" value={templatetype} onChange={handleFileTypeChange}>
                  <option value="">--Select--</option>
                  <option value="Document">Document</option>
                  <option value="Image">Image</option>
                  <option value="Video">Video</option>
                  <option value="Text">Text</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form_group1">
                <label htmlFor="template">Template <span className='required_icon'>*</span></label>
                <select id="template" value={template} onChange={handletemplatename}>
                  <option value="">None</option>
                  {templatedata.map((item) => (
                    <option key={item.id} value={item.name}>{item.name}</option>
                  ))}

                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form_group1 form_groupInput">
                <label htmlFor="url">Media url <span className='required_icon'>*</span></label>
                <input type="text" id="url" ref={checkurldata} onChange={handleurlchange} placeholder="Enter Media url" disabled={fileType === 'Text'} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form_group1 form_groupInput">
                <label htmlFor="file">OR</label>
                {/* <input type="file" id="file"  className="p_7"/> */}
                <input
                  type="file" id="file"
                  className="p_7"
                  ref={checkfiledata}
                  onChange={handleUserFilechange}
                  disabled={fileType === 'Text'}

                  accept={fileType === 'Document' ? 'application/pdf' : fileType === 'Image' ? 'image/*' : fileType === 'Video' ? 'video/*' : fileType === 'Text' ? 'text/plain' : ''}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form_group1 form_groupInput">
                <label htmlFor="message">Message <span className='required_icon'>*</span></label>
                <textarea name="message" value={message} onChange={e => setmessage(e.target.value)} id="message" rows="5" placeholder="message" disabled readOnly ></textarea>
                <span className="CharLimit">Max Characters : {maxLimit}/1024</span>
              </div>
            </div>
            <div className="col-12">
              <div className="form_group1 mt-0 text-center">
                {/* <button type="button" data-bs-toggle="modal" data-bs-target="#Sms_privew_modal" >Send SMS</button> */}
                <button type="button" onClick={SentData} >Send Whtsapp</button>
              </div>
            </div>
          </div>

          <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" aria-labelledby="Sms_privew_modal" aria-hidden={!showModal}>
            <div className="modal-dialog">
              <div className="modal-header">
                <h5 className="modal-title text-center w-100" id="Sms_privew_modal">SMS Preview</h5>
              </div>
              <div className="modal-content Campaigns_Modal_contain">
                <div className="modal-body p-0">
                  <table className="Modal_table">
                    <thead>
                      <tr>
                        <th>Mobile</th>
                        <th>Message</th>
                        <th>Length</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modalData.map((data, index) => (
                        <tr key={index}>
                          <td>{data.mobile}</td>
                          <td className="Message_modal"><p>{data.message}</p></td>
                          <td>{data.length}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="3" className="text-center">
                          Total Contact: <span>{totalContacts}</span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <div className="form_group1 mt-3 text-center">
                    <button type="button" onClick={handleSendNow} disabled={loading} className={loading ? 'd-none Modal_save_btn' : 'Modal_save_btn'}>
                      {loading ? 'Sending...' : 'Send Now'}
                    </button>
                    <button type="button" className={loading ? '' : 'd-none'} disabled={loading}>
                      {loading ? 'Sending... ' : ''}
                    </button>
                    <button type="button" className={`Modal_cancel_btn ms-2 ${loading ? 'd-none' : ''}`} onClick={() => setShowModal(false)} aria-label="Close"> Close</button>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* template id */}
      <input
        type="hidden"
        value={templateID}
        className='d-none'
      />
      {/* template name */}
      <input
        type="hidden"
        value={templatename}
        className='d-none'
      />

      {/* template category */}
      <input
        type="hidden"
        value={templatecat}
        className='d-none'
      />

      {/* template language  */}

      <input
        type="hidden"
        value={templatelang}
        className='d-none'
      />


      <input
        type="file"
        ref={fileInputRef}
        className='d-none'
        onChange={handleFileChange}
        accept='.csv'
      />


      <ShowSnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={snackBar.message}
        onClose={handleCloseSnackBar}
      />
      {loader && (
        <div className="spinnerOverlay">
          <ClipLoader loading={loader} size={50} />
        </div>
      )}
    </>
  )
}

export default Wb_quick_campaigns
